import React from "react";
import dynamic from "next/dynamic";

import images from "../../assets/images";
import Link from "next/link";

const NextImage = dynamic(() => import("../NextElements/NextImage"), {
  ssr: true,
});

const Logo = (props) => {
  return (
    <Link href="/">
      <a style={{ cursor: "pointer" }}>
        <NextImage
          src={images.image_logo.src}
          className={`${props.className}`}
          alt={props.alt ? props.alt : "Logo"}
          priority={true}
          width={144}
          height={46}
        />
      </a>
    </Link>
  );
};

export default React.memo(Logo);
