import image_logo from '../public/images/logo/logo.svg'

import image_banner from '../public/images/banner/banner-img.png'

// Status
import ic_banner from '../public/images/status/banner.png'
import ic_received from '../public/images/status/received.png'
import ic_status from '../public/images/status/status.svg'

// Icons
import ic_back from '../public/images/icon/back.svg'
import ic_print from '../public/images/icon/print.svg'
import ic_pin from '../public/images/icon/pin.png'

// Delivery Icons
import ic_ds_received from '../public/images/delivery/received.svg'
import ic_ds_shipped from '../public/images/delivery/shipped.svg'
import ic_ds_transit from '../public/images/delivery/transit.svg'
import ic_ds_out from '../public/images/delivery/out.svg'
import ic_ds_delivered from '../public/images/delivery/delivered.svg'
import ic_ds_failed from '../public/images/delivery/failed.svg'


export default {
    image_logo,
    image_banner,
    ic_banner,
    ic_received,
    ic_back,
    ic_print,
    ic_status,
    ic_ds_received,
    ic_ds_shipped,
    ic_ds_transit,
    ic_ds_out,
    ic_ds_delivered,
    ic_ds_failed,
    ic_pin
};
