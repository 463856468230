import { useState } from 'react';
import style from '../../../styles/FAQs.module.scss';
import { FAQList } from '../../../utils/helpers';
const FAQs = () => {
    const [open, setopen] = useState(0)
    return (
        <section className={style.faqWrap}>
            <div className={`${style.faqContainer} container`}>
                <h2 className={style.header}>
                    FAQs (Frequently Asked Questions)
                </h2>
                <ul className={style.list}>
                    {FAQList.map((card, key) => (
                        <li className={style.listItem} key={key}>
                            <input type="checkbox" checked={key === open ? false : true} onChange={() => key === open ? setopen(100) : setopen(key)} />
                            <i className={style.listItemIcon}></i>
                            <h2>{card.title}</h2>
                            <p className={style.text}>
                                {card.caption}
                            </p>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default FAQs;