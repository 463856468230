import images from "../assets/images";
export const StatusList = [
  {
    title: "Order Received",
    caption:
      "Your order has been successfully received and we are taking care of it",
    icon: images.ic_ds_received.src,
  },
  {
    title: "Order Shipped",
    caption:
      "You order is now under shipment and our warehouse team will take care of it",
    icon: images.ic_ds_shipped.src,
  },
  {
    title: "Order In Transit",
    caption:
      "Your order is now successfully loaded on to our van and our delivery team will take care of it",
    icon: images.ic_ds_transit.src,
  },
  {
    title: "Out For Delivery",
    caption:
      "Your order is now with our delivery team and it’s out to deliver at your chosen delivery address",
    icon: images.ic_ds_out.src,
  },
  {
    title: "Delivered",
    caption:
      "You order is successfully delivered at your property by our expert team of delivery men",
    icon: images.ic_ds_delivered.src,
  },
];

export const FAQList = [
  {
    title: "When will my order be delivered?",
    caption:
      "Most orders are delivered between 2 – 6 weeks depending on style, fabric and finish. You can find more accurate production times on your order confirmation.",
  },
  {
    title: "Will I get phone call or email to notify me for the delivery?",
    caption:
      "Yes, we will contact you in advance via phone, SMS and email to arrange delivery.",
  },
  {
    title: "Can I change my delivery date?",
    caption:
      "If you are not available to accept the delivery date offered, we can reschedule your delivery but please be aware it could take another 4 – 6 weeks.",
  },
  {
    title: "Do I have to take my furniture inside the property?",
    caption:
      "No, we have 2 man delivery team that will delivery your order to your room choice on the ground floor. If you require delivery higher than ground floor, please contact us to discuss your needs.",
  },
  {
    title: "Do I have to remove all packaging and waste?",
    caption:
      "No, our delivery men will take away all packaging and waste. Please let them know whilst they delivering your products.",
  },
  {
    title: "I need more information for the delivery, how do I contact you?",
    caption:
      "Sure, you can keep in touch us via Live chat from our website, email or call us on 01254 268590. ",
  },
];

export const TrackList = [
  {
    day: "Monday 01 March 2023",
    time: "12:10",
    status: "Order Received",
  },
  {
    day: "Monday 03 March 2023",
    time: "11:00",
    status: "Order Shipped",
  },
];

export const FooterMainLinks = [
  {
    title: "About Us",
    to: "https://www.designersofas4u.co.uk/company-today",
  },
  {
    title: "Newsroom",
    to: "https://www.designersofas4u.co.uk/blog",
  },
];

export const FooterOtherLinks = [
  {
    title: "FAQS",
    to: "https://www.designersofas4u.co.uk/faqs",
  },
  {
    title: "Terms and Conditions",
    to: "https://www.designersofas4u.co.uk/terms-and-conditions",
  },
  {
    title: "Privacy Policy",
    to: "https://www.designersofas4u.co.uk/privacy-policy",
  },
];
