import Link from "next/link";
import React from "react";
import style from "../../../styles/Footer.module.scss";
import { FooterMainLinks, FooterOtherLinks } from "../../../utils/helpers";
const Footer = () => {
  return (
    <section className={style.footerWrap}>
      <div className={`${style.footerContainer} container`}>
        <div className={style.content}>
          <div className={style.mainLinks}>
            <ul className={style.mainLinksList}>
              {FooterMainLinks.map((ele, key) => (
                <React.Fragment key={key}>
                  <Link href={ele.to}>
                    <a href={ele.to} target="_blank">
                      <li className={style.mainLinkItem}>
                        <p>{ele.title}</p>
                      </li>
                    </a>
                  </Link>
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div className={style.otherLinks}>
            <ul className={style.otherLinksList}>
              <Link href={"/support"}>
                <a href={"/support"}>
                  <li className={style.otherLinkItem}>
                    <p>Support</p>
                  </li>
                </a>
              </Link>
              {FooterOtherLinks.map((ele, key) => (
                <React.Fragment key={key}>
                  <Link href={ele.to}>
                    <a href={ele.to} target="_blank">
                      <li className={style.otherLinkItem}>
                        <p>{ele.title}</p>
                      </li>
                    </a>
                  </Link>
                </React.Fragment>
              ))}
            </ul>
          </div>
          <div className={style.footerText}>
            <p className={style.copyright}>
              © 2007–2023
              <Link href={`https://www.designersofas4u.co.uk/`}>
                <a
                  href={`https://www.designersofas4u.co.uk/`}
                  style={{ paddingLeft: `4px` }}
                  target="_blank"
                >
                  <span>Designer Sofas For You Ltd.</span>
                </a>
              </Link>
            </p>
            <p className={style.brand}>Slick Technologies Pvt Ltd.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
