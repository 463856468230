import React from "react";
import Logo from "../../Logo";
import Link from "next/link";

const Navbar = () => {
  return (
    <section className="nav-wrap">
      <div className={`container`}>
        <div className="nav">
          <input type="checkbox" id="nav-check" />
          <div className="nav-header">
            <div className="nav-logo">
              <Logo className="nav-logo-img" />
            </div>
          </div>
          <div className="nav-btn">
            <label htmlFor="nav-check">
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>
          <div className="nav-links">
            <Link href="/">
              <a href="/" className="add-deliveries">
                Deliveries
              </a>
            </Link>
            <Link href="/support">
              <a href="/support" className="add-support">
                Support
              </a>
            </Link>
            <a href="https://www.designersofas4u.co.uk/faqs" target="_blank">
              FAQS
            </a>
            <a
              href="https://www.designersofas4u.co.uk/terms-and-conditions"
              target="_blank"
            >
              Terms and Conditions
            </a>
            <a
              href="https://www.designersofas4u.co.uk/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Navbar);
