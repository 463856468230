import React from "react";
import Image from "next/image";

const NextImage = ({ className, alt, src, priority, width, height }) => {
  return (
    <div className={className}>
      <Image
        src={src}
        alt={alt ? alt : "Icon"}
        layout="fill"
        priority={priority ? priority : false}
      />
    </div>
  );
};

export default React.memo(NextImage);
